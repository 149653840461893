<template>
    <div class="animate__animated animate__fadeIn">
        BAUSTELLE
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {}
        },
        beforeRouteEnter(to, from, next) { // Prevent not signed in user from access this site
            next(vm => {
                if (vm.$user.user.auth === false)
                    next({path: vm.$variables.v.navigation.page404})
            })
        },
    }
</script>
